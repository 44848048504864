import React from "react";
import Icons from "../../assets/icons";
import { NavLink, useLocation } from "react-router-dom";

const MenuItem = ({ onClick, link, text }) => {
  return (
    <li onClick={onClick}>
      <NavLink
        to={link}
        className={({ isActive }) =>
          `block text-sm uppercase font-semibold !leading-[16.8px] tracking-[1.68px] text-grey-900 nav-link ${
            isActive ? "text-primary" : ""
          }`
        }
      >
        {text}
      </NavLink>
    </li>
  );
};

const MenuDropDownItem = ({ text, children, active }) => {
  return (
    <li className="relative link-with-dropDown w-full justify-between flex items-center gap-2">
      <span
        className={`cursor-pointer text-sm uppercase font-semibold !leading-[16.8px] tracking-[1.68px] whitespace-nowrap ${
          active ? "text-primary" : "text-grey-90 "
        }`}
      >
        {text}
      </span>
      <span
        className={`-rotate-90 lg:rotate-0  ${
          active ? "text-primary" : "text-grey-90"
        } `}
      >
        {Icons.ic_down}
      </span>
      <DropDown>{children}</DropDown>
    </li>
  );
};

const DropDown = ({ children }) => {
  return (
    <div className="z-90 rounded-lg w-[267px] border border-grey-200 bg-white absolute top-8 -left-2 drop-down">
      {children}
    </div>
  );
};

const DropDownItem = ({ title, desc, link }) => {
  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        `block text-sm !leading-[20.3px] border-b last:border-none border-grey-10 py-4 lg:p-4 ${
          isActive ? "bg-grey-10" : ""
        }`
      }
    >
      <p className="text-grey-900 text-sm font-semibold">{title}</p>
      <p className="text-grey-500">{desc}</p>
    </NavLink>
  );
};

const DesktopNavbar = ({
  toggleContactModal,
  isContactModalOpen,
  toggleShowForm,
}) => {
  const { pathname } = useLocation();
  return (
    <nav
      className={`desktop-nav -ml-[25px] xl:-ml-[35px] static top-0 left-0 flex flex-row bg-transparent px-0 py-0 z-30 transition-all ease-in-out duration-300 w-auto justify-between items-center gap-4 visible max-h-fit translate-y-0 ${
        isContactModalOpen ? "hidden" : ""
      }`}
    >
      <ul className={`flex flex-row items-center justify-between gap-8 w-auto`}>
        <MenuDropDownItem
          text={"Company"}
          active={
            pathname === "/about" ||
            pathname === "/management" ||
            pathname === "/code-of-conduct"
              ? true
              : false
          }
        >
          <DropDownItem title="About Us" desc="How we started" link="/about" />
          <DropDownItem
            title="Our Management Team"
            desc="Get to meet the team"
            link="/management"
          />
          <DropDownItem
            title="Code of Conduct"
            desc="Our business ethics policy"
            link="/code-of-conduct"
          />
        </MenuDropDownItem>
        <MenuDropDownItem
          text={"Services"}
          active={
            pathname === "/services" ||
            pathname === "/initiatives" ||
            pathname === "/energy-transition" ||
            pathname === "/partnerships"
              ? true
              : false
          }
        >
          <DropDownItem
            title="What we offer"
            desc="Enhancing gas sector growth"
            link="/services"
          />
          <DropDownItem
            title="GACN Initiatives"
            desc="Driving innovation"
            link="/initiatives"
          />
          <DropDownItem
            title="GACN Energy Transition"
            desc="Our energy transition initiative"
            link="/energy-transition"
          />
          <DropDownItem
            title="GACN Partnerships"
            desc="Forging progress"
            link="/partnerships"
          />
        </MenuDropDownItem>

        <MenuItem link="/purchase-process" text={"Gas Purchase process"} />

        <MenuDropDownItem
          text={"News & insight"}
          active={
            pathname === "/news" ||
            pathname === "/publications" ||
            pathname === "/gallery"
              ? //   ||
                // pathname === "/branding"
                true
              : false
          }
        >
          <DropDownItem
            title="News Update"
            desc="Recent news in the industry"
            link="/news"
          />
          <DropDownItem
            title="Publications"
            desc="Helpful tips, resources"
            link="/publications"
          />
          <DropDownItem
            title="Photo gallery"
            desc="Explore our gallery"
            link="/gallery"
          />
          {/* <DropDownItem
            title="Media & Branding"
            desc="Our videos and brand guidelines"
            link="/branding"
          /> */}
        </MenuDropDownItem>
      </ul>
    </nav>
  );
};

export default DesktopNavbar;
