import React from "react";
import { object, string } from "yup";
import { useState } from "react";
import { Form, Formik } from "formik";
import Input from "../../common/input";
import Icons from "../../assets/icons";
import TextArea from "../../common/textarea";
import Loader from "../../common/loader";

function ContactForm({ toggleShowForm }) {
  const [formError, setFormError] = useState("");

  const submitForm = async (values) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullname: values.full_name,
        email: values.email,
        phone: values.phone_number,
        company: values.company_name,
        message: values.message,
      }),
    };

    try {
      const response = await fetch(
        "https://gacn-nest-server-2f0f7095d25c.herokuapp.com/api/contact/sendemail",
        options
      );

      if (response.status === 201) toggleShowForm(false);
      else setFormError("Something went wrong");
    } catch (error) {
      // console.log(error);
      setFormError("Something went wrong");
    }
  };

  return (
    <div className="mt-[119px] flex flex-col gap-8 sm:max-w-[400px] mx-auto pb-10">
      <div>
        <h3 className="text-[28px] text-grey-900 font-semibold tracking-[-0.56px] pb-4 ">
          Contact us
        </h3>
        <p className="text-grey-500">
          Please complete the details below and we’ll be in contact
        </p>
      </div>

      {formError && (
        <p className="text-error bg-error-50 rounded-lg py-2 px-4">
          {formError}
        </p>
      )}

      <Formik
        initialValues={{
          full_name: "",
          email: "",
          phone_number: "",
          company_name: "",
          message: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          submitForm(values).then((_) => setSubmitting(false));
        }}
        validationSchema={object().shape({
          full_name: string()
            .required("Please enter your full name")
            .matches(
              /^[A-Za-z'-]+\s[A-Za-z'-]+(?:\s[A-Za-z'-]+)*$/,
              "Full name is incorrect"
            ),
          email: string()
            .email("Please enter a valid email")
            .required("Please enter your email"),
          phone_number: string()
            .required("Please enter your phone number")
            .matches(/^(\d{11})$/, "Phone number is incorrect"),
          company_name: string().required("Please enter your company name"),
          message: string().required("Please enter a message"),
        })}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isValid,
            dirty,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props;

          return (
            <Form className="flex flex-col gap-16">
              <div className="flex flex-col gap-6 max-h-[336px] md:max-h-[374px] overflow-auto scrollbar ">
                <Input
                  title="FULL NAME"
                  icon={Icons.ic_user}
                  placeholder="First and last name"
                  type="text"
                  name="full_name"
                  id="full_name"
                  value={values.full_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // error={errors.full_name && touched.full_name ? true : false}
                  errors={
                    errors.full_name && touched.full_name
                      ? errors.full_name
                      : ""
                  }
                />
                <Input
                  title="EMAIL"
                  icon={Icons.ic_email}
                  placeholder="Eample@email.com"
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // error={errors.email && touched.email ? true : false}
                  errors={errors.email && touched.email ? errors.email : ""}
                />
                <Input
                  title="PHONE NUMBER"
                  icon={Icons.ic_phone}
                  placeholder="+234"
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  value={values.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // error={
                  //   errors.phone_number && touched.phone_number ? true : false
                  // }
                  errors={
                    errors.phone_number && touched.phone_number
                      ? errors.phone_number
                      : ""
                  }
                />
                <Input
                  title="YOUR COMPANY NAME"
                  icon={Icons.ic_company}
                  placeholder="Name of your organisation"
                  type="text"
                  name="company_name"
                  id="company_name"
                  value={values.company_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // error={
                  //   errors.company_name && touched.company_name ? true : false
                  // }
                  errors={
                    errors.company_name && touched.company_name
                      ? errors.company_name
                      : ""
                  }
                />
                <TextArea
                  title="LEAVE A MESSAGE"
                  placeholder="Enter an enquiry or a suggestion"
                  name="message"
                  id="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // error={errors.message && touched.message ? true : false}
                  errors={
                    errors.message && touched.message ? errors.message : ""
                  }
                />
              </div>
              <button
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                className="w-full disabled:bg-grey-300 bg-primary text-white py-4 px-6 rounded-lg font-semibold"
              >
                {isSubmitting ? <Loader /> : "Send message"}
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ContactForm;
